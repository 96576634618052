import React, { useEffect, useState } from 'react';
import './Popular.css';
import Item from '../Item/Item';

const API_HOST = process.env.REACT_APP_API_HOST;

export const Popular = () => {

  const [popularProducts, setPopularProducts] = useState([]);

  useEffect(() => {
    fetch(`${API_HOST}/popularinwomen`)
    .then((response) => response.json())
    .then((data) => setPopularProducts(data))
  }, []);

  return (
    <div className='popular'>
        <h1>POPULAR EM MULHERES</h1>
        <hr />
        <div className="popular-item">
            {popularProducts.map((item, i) => {
                return <Item key={i} id={item.id} name={item.name} image={item.images[0]} new_price = {item.new_price} old_price={item.old_price} />
            })}
        </div>
    </div>
  )
}

export default Popular;