import React, { useContext, useEffect,useState } from 'react';
import './Product.css';
import { ShopContext } from '../../Context/ShopContext';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import { useParams } from 'react-router-dom';
import ProductDisplay from '../../Components/ProductDisplay/ProductDisplay';
import DescriptionBox from '../../Components/DescriptionBox/DescriptionBox';
import RelatedProduct from '../../Components/RelatedProduct/RelatedProduct';


const Product = () => {
  const { all_product } = useContext(ShopContext);
  const { productId } = useParams();

  const [key, setKey] = useState(0);

  useEffect(() => {
    // Atualiza o estado para forçar a remontagem do componente ProductDisplay
    setKey((prevKey) => prevKey + 1);
  }, [productId]);

  if (!all_product) {
    return <p>Carregando...</p>;
  }

  const product = all_product.find((e) => e.id === Number(productId));

  if (!product) {
    return <p>Produto não encontrado</p>;
  }

  return (
    <div key={key} className='product'>
      <Breadcrumbs product={product} />
      <ProductDisplay product={product} />
      <DescriptionBox />
      <RelatedProduct product={product} />
    </div>
  );
};

export default Product;