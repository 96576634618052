import React, { createContext, useEffect, useState } from "react";

const API_HOST = process.env.REACT_APP_API_HOST;

export const ShopContext = createContext(null);

const getDefaultCart = () => {
    let cart = {};
    for (let i = 0; i < 301; i++) {
        cart[i] = {
            name: "",
            image: "",
            price: 0,   
            quantity0: 0,  
            quantity1: 0,
            quantity2: 0,
            link: "",
            size: "",
            sizes: [],
            color: "",
            productId: "",
        };
    }
    return cart;
};


const ShopContextProvider = (props) => {

    const [all_product, setAllProduct] = useState([]);
    const [cartItems, setCartItems] = useState(getDefaultCart);
    const [scrollToNewCollection, setScrollToNewCollection] = useState(false);

    useEffect(() => {
        fetch(`${API_HOST}/allproducts`)
            .then((response) => response.json())
            .then((data) => {
                setAllProduct(data);
    
                if (localStorage.getItem('auth-token')) {
                    fetch(`${API_HOST}/getcart`, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/form-data',
                            'auth-token': `${localStorage.getItem('auth-token')}`,
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((response) => response.json())
                    .then((data) => setCartItems(data))
                    .catch((error) => console.error('Error fetching cart items:', error));
                } else {
                    // Se o usuário não estiver autenticado, verifique se há itens no carrinho no localStorage
                    const storedCartItems = JSON.parse(localStorage.getItem('cartItems'));
                    if (storedCartItems) {
                        setCartItems(storedCartItems);
                    }
                }
            })
            .catch((error) => console.error('Error fetching products:', error));
    }, []);
    
      
    const addToCart = (product, selectedSize, i) => {
        const quantityKey = `quantity${Math.floor(i / 5)}`;

        setCartItems((prev) => {
            const existingCartItem = prev[product.id] || {};
    
            const newQuantity = existingCartItem[quantityKey] ? existingCartItem[quantityKey] + 1 : 1;
    
            const updatedCart = {
                ...prev,
                [product.id]: {
                    ...existingCartItem,
                    [quantityKey]: newQuantity,
                    name: product.name,
                    image: product.images[i],
                    price: product.new_price,
                    link: product.link,
                    size: selectedSize,
                    sizes: [...(existingCartItem.sizes || []), selectedSize],
                    color: product.color,
                    productId: product._id
                }
            };
    
            localStorage.setItem('cartItems', JSON.stringify(updatedCart));
            return updatedCart;
        });
    
        if (localStorage.getItem('auth-token')) {
            fetch(`${API_HOST}/addtocart`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'auth-token': `${localStorage.getItem('auth-token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    itemId: product.id,
                    name: product.name,
                    image: product.images[0],
                    price: product.new_price,
                    link: product.link,
                    size: selectedSize,
                    quantityField: quantityKey,
                    color: product.color,
                    productId: product._id
                }),
            })
            .catch((error) => console.error('Error:', error));
        }
    };
    
    
    const removeFromCart = (product, quantityIndex) => {
        setCartItems((prev) => {
            const existingCartItem = prev[product.id];
            if (!existingCartItem) return prev;
    
            const quantityToRemove = existingCartItem[`quantity${quantityIndex}`];
            if (quantityToRemove <= 0) return prev;
    
            const updatedSizes = existingCartItem.sizes.filter((_, index) => index !== quantityIndex);
            const updatedQuantities = { ...existingCartItem };
            updatedQuantities[`quantity${quantityIndex}`] = Math.max(0, quantityToRemove - 1);
    
            const updatedCart = {
                ...prev,
                [product.id]: {
                    ...existingCartItem,
                    ...updatedQuantities,
                    sizes: updatedSizes,
                    name: product.name,
                    image: product.images[0],
                    link: product.link,
                }
            };
    
            const shouldRemoveProduct = Object.keys(updatedQuantities)
                .filter(key => key.startsWith('quantity'))
                .every(key => updatedQuantities[key] <= 0);
    
            const finalCart = shouldRemoveProduct
                ? Object.keys(updatedCart)
                    .filter(key => key !== product.id.toString())
                    .reduce((acc, key) => ({ ...acc, [key]: updatedCart[key] }), {})
                : updatedCart;
    
            localStorage.setItem('cartItems', JSON.stringify(finalCart));
    
            if (localStorage.getItem('auth-token')) {
                fetch(`${API_HOST}/removefromcart`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'auth-token': `${localStorage.getItem('auth-token')}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({  itemId: product.id,
                                            quantityField: `quantity${quantityIndex}`
                     }),
                })
                .catch((error) => console.error('Error:', error));
            }
    
            return finalCart;
        });
    };


    const getTotalCartAmount = () => {
        let totalAmount = 0;
    
        for (const item in cartItems) {
            const cartItem = cartItems[item];
            
            let itemInfo = all_product.find((product) => product.id === Number(item));
    
            if (itemInfo) {
                let totalQuantity = 0;

                for (const key in cartItem) {
                    if (key.startsWith('quantity') && cartItem[key] > 0) {
                        totalQuantity += cartItem[key];
                    }
                }
                totalAmount += itemInfo.new_price * totalQuantity;
            }
        }
    
        return totalAmount;
    };
    
    const getTotalCartItems = () => {
        let totalCartItems = 0;
    
        for (const item in cartItems) {
            const cartItem = cartItems[item];

            for (const key in cartItem) {
                if (key.startsWith('quantity') && cartItem[key] > 0) {
                    totalCartItems += cartItem[key];
                }
            }
        }
        return totalCartItems;
    };
    

    const contextValue = {all_product, cartItems, addToCart, removeFromCart, getTotalCartAmount, getTotalCartItems, scrollToNewCollection, setScrollToNewCollection};
    return (
        <ShopContext.Provider value={contextValue}>
            {props.children}
        </ShopContext.Provider>
    )
};

export default ShopContextProvider;