import React, { useEffect, useState, useContext, useRef } from 'react';
import './NewCollection.css';
import Item from '../Item/Item';
import { ShopContext } from '../../Context/ShopContext';

const API_HOST = process.env.REACT_APP_API_HOST;

const NewCollection = () => {
  const [new_collection, setNewcollection] = useState([]);
  const { setScrollToNewCollection, scrollToNewCollection } = useContext(ShopContext);
  const newCollectionRef = useRef(null);

  useEffect(() => {
    if (scrollToNewCollection) {
      newCollectionRef.current.scrollIntoView({ behavior: 'smooth' });
      setScrollToNewCollection(false);
    }
  }, [scrollToNewCollection, setScrollToNewCollection]);

  useEffect(() => {
    fetch(`${API_HOST}/newcollections`)
      .then((response) => response.json())
      .then((data) => setNewcollection(data))
      .catch((error) => console.error('Error fetching new collections:', error));
  }, []);

  const womenItems = new_collection.filter(item => item.category === 'women');
  const menItems = new_collection.filter(item => item.category === 'men');

  const sortedItems = [...womenItems, ...menItems];

  return (
    <div ref={newCollectionRef} className='new-collection'>
      <h1>NOVAS COLEÇÕES</h1>
      <hr />
      <div className="collections">
        {sortedItems.map((item, i) => (
          <Item
            key={i}
            id={item.id} 
            name={item.name}
            image={item.images[0]}
            new_price={item.new_price}
            old_price={item.old_price}
          />
        ))}
      </div>
    </div>
  );
};

export default NewCollection;
