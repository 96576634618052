import React, { useState } from 'react';
import './NewsLetter.css';

export const NewsLetter = () => {

  const [email, setEmail] = useState("");

  const subscribe = () => {
    setEmail("");
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className='newsletter'>
        <h1>Receba Ofertas Exclusivas Em Seu E-mail</h1>
        <p>Assine nossa newsletter e fique atualizado</p>

        <div>
            <input value = {email} onChange={handleEmailChange}  type="email" placeholder="Digite seu e-mail" />
            <button onClick={ subscribe }>Se inscrever</button>
        </div>
    </div>
  )
}

export default NewsLetter;