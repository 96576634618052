import React, { useEffect, useState } from 'react';
import './RelatedProduct.css';
import Item from '../Item/Item';

const API_HOST = process.env.REACT_APP_API_HOST;

export const RelatedProduct = (props) => {

  const { product } = props;
  const [data_product, setDataProduct] = useState([]);

  useEffect(() => {
    fetch(`${API_HOST}/relatedproducts`, {
      method: 'POST',
      headers: {
        Accept: 'application/json', 
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: product.type,
        name: product.name,
        id: product.id,
        category: product.category,
      }),
    })
    .then((response) => response.json())
    .then((data) => setDataProduct(data))
    .catch((error) => console.error('Error fetching related products:', error));
  }, [product.type, product.name, product.id, product.category]);
  
  return (
    <div className='relatedProducts'>
        <h1>Produtos relacionados</h1>
        <hr/>

        <div className="relatedProducts-item">
            {data_product.map((item, i) => {
                return <Item key={i} id={item.id} name={item.name} image={item.images[0]} new_price = {item.new_price} old_price={item.old_price}/>
        })}
        </div>
    </div>
  )
}

export default RelatedProduct;