// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
    width: 280;
}
.item img {
    width: 280px;
}
.item p {
    margin: 6px 0px;
}
.item-prices {
    display: flex;
    gap: 20px;
}
.item-price-new {
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}
.item-price-old {
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}
.item:hover {
    transform: scale(1.05);
    transition: 0.6s;
}
@media(max-width:1900px) {
    .item {
        font-size: 15px;
    }
    .item img {
        width: 260px;

    }
    .item-price-old {
        font-size: 14px;
    }
}
@media(max-width:1024px) {
    .item {
        width: 170px;
        font-size: 13px;
    }
    .item img {
        width: 170px;
    }
    .item-price-old {
        font-size: 13px;
    }
}
@media(max-width:800) {
    .item {
        width: 120px;
        font-size: 12px;
    }
    .item img {
        width: 120px;
    }
    .item-price-old {
        font-size: 12px;
    }
    .item-price-new {
        font-size: 17px;
    }
}
@media(max-width:500) {
    .item {
        width: 160px;
    }
    .item img {
        width: 160px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Item/Item.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,YAAY;AAChB;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,6BAA6B;AACjC;AACA;IACI,sBAAsB;IACtB,gBAAgB;AACpB;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,YAAY;;IAEhB;IACA;QACI,eAAe;IACnB;AACJ;AACA;IACI;QACI,YAAY;QACZ,eAAe;IACnB;IACA;QACI,YAAY;IAChB;IACA;QACI,eAAe;IACnB;AACJ;AACA;IACI;QACI,YAAY;QACZ,eAAe;IACnB;IACA;QACI,YAAY;IAChB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ;AACA;IACI;QACI,YAAY;IAChB;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".item {\n    width: 280;\n}\n.item img {\n    width: 280px;\n}\n.item p {\n    margin: 6px 0px;\n}\n.item-prices {\n    display: flex;\n    gap: 20px;\n}\n.item-price-new {\n    color: #374151;\n    font-size: 18px;\n    font-weight: 600;\n}\n.item-price-old {\n    color: #8c8c8c;\n    font-size: 18px;\n    font-weight: 500;\n    text-decoration: line-through;\n}\n.item:hover {\n    transform: scale(1.05);\n    transition: 0.6s;\n}\n@media(max-width:1900px) {\n    .item {\n        font-size: 15px;\n    }\n    .item img {\n        width: 260px;\n\n    }\n    .item-price-old {\n        font-size: 14px;\n    }\n}\n@media(max-width:1024px) {\n    .item {\n        width: 170px;\n        font-size: 13px;\n    }\n    .item img {\n        width: 170px;\n    }\n    .item-price-old {\n        font-size: 13px;\n    }\n}\n@media(max-width:800) {\n    .item {\n        width: 120px;\n        font-size: 12px;\n    }\n    .item img {\n        width: 120px;\n    }\n    .item-price-old {\n        font-size: 12px;\n    }\n    .item-price-new {\n        font-size: 17px;\n    }\n}\n@media(max-width:500) {\n    .item {\n        width: 160px;\n    }\n    .item img {\n        width: 160px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
