import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './ShopCategory.css';
import { ShopContext } from '../../Context/ShopContext';
import Item from '../../Components/Item/Item';

export const ShopCategory = (props) => {
  const { all_product } = useContext(ShopContext);
  const [sort, setSort] = useState("");
  const [displayProducts, setDisplayProducts] = useState("Todos Produtos");
  const [visibleCount, setVisibleCount] = useState(12); 
  const location = useLocation();

  const getStorageKey = (key) => `${props.category}_${key}`;

  useEffect(() => {
    const savedState = JSON.parse(sessionStorage.getItem(getStorageKey('shopCategoryState')));
    if (savedState) {
      setSort(savedState.sort);
      setDisplayProducts(savedState.displayProducts);
      setVisibleCount(savedState.visibleCount);
    } else {
      setSort("");
      setDisplayProducts("Todos Produtos");
      setVisibleCount(12);
    }

    const scrollPosition = sessionStorage.getItem(getStorageKey('scrollPosition'));
    if (scrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(scrollPosition, 10));
      }, 400); 
    }
  }, [location.pathname, props.category]);

  useEffect(() => {
    sessionStorage.setItem(getStorageKey('shopCategoryState'), JSON.stringify({ sort, displayProducts, visibleCount }));

    const handleScroll = () => {
      sessionStorage.setItem(getStorageKey('scrollPosition'), window.scrollY);
      
      if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 300) {
        setVisibleCount((prevCount) => prevCount + 12);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sort, displayProducts, visibleCount, props.category]);

  useEffect(() => {
    setDisplayProducts("Todos Produtos");
  }, [props.category]);

  const sortProducts = (type) => {
    const sortedProducts = [...all_product];
    if (type === "Preço Ascendente") {
      sortedProducts.sort((a, b) => a.new_price - b.new_price);
    } else if (type === "Preço Descendente") {
      sortedProducts.sort((a, b) => b.new_price - a.new_price);
    }
    return sortedProducts;
  };

  const renderCategoryItems = () => {
    return sortProducts(sort)
      .filter((item) => (props.category === item.category && (displayProducts === item.type || displayProducts === "Todos Produtos")))
      .slice(0, visibleCount) 
      .map((item, i) => (
        <Item key={i} id={item.id} name={item.name} image={item.images[0]} new_price={item.new_price} old_price={item.old_price} />
      ));
  };

  return (
    <div className='shop-category'>
      <img className='shop-category-banner' src={props.banner} alt='' />

      <div className="shopcategory-indexSort">
        <div className="show-products">
          <select value={displayProducts} onChange={(e) => { setDisplayProducts(e.target.value) }} name="displayProducts" className='sort-selector displayProducts'>
            <option value="Todos Produtos">Todos Produtos</option>
            {props.category === 'women' && (
              <>
                <option value="Blazers">Blazers</option>
                <option value="Vestidos">Vestidos</option>
                <option value="Saias">Saias</option>
                <option value="Calções">Calções</option>
                <option value="Tops|Bodies">Tops|Bodies</option>
                <option value="Camisas">Camisas</option>
                <option value="T-Shirts">T-Shirts</option>
                <option value="Jeans">Jeans</option>
                <option value="Calças">Calças</option>
                <option value="Cardigan|Camisolas">Cardigan|Camisolas</option>
                <option value="Sweattshirt">Sweattshirt</option>
                <option value="Coletes">Coletes</option>
                <option value="Lingerie">Lingerie</option>
                <option value="Bolsas">Bolsas</option>
              </>
            )}
            {props.category === 'men' && (
              <>
                <option value="Camisas">Camisas</option>
                <option value="T-Shirts">T-Shirts</option>
                <option value="Polos">Polos</option>
                <option value="Calças">Calças</option>
                <option value="Calças De Ganga">Calças De Ganga</option>
                <option value="Bermudas">Bermudas</option>
                <option value="Fatos">Fatos</option>
                <option value="Banho">Banho</option>
                <option value="Sweattshirt">Sweattshirt</option>
                <option value="Camisola|Casacos">Camisola|Casacos</option>
                <option value="Camisas Compridas">Camisas Compridas</option>
                <option value="Blazers">Blazers</option>
                <option value="Sapatos">Sapatos</option>
              </>
            )}
            {props.category === 'kid' && (
              <>
                <option value="1 - 6 Anos - Menina">1 - 6 Anos - Menina</option>
                <option value="6 - 14 Anos - Menina">6 - 14 Anos - Menina</option>
                <option value="1 - 6 Anos - Menino">1 - 6 Anos - Menino</option>
                <option value="6 - 14 Anos - Menino">6 - 14 Anos - Menino</option>
                <option value="0 - 6 Meses">0 - 6 Meses</option>
                <option value="6 - 18 Meses">6 - 18 Meses</option>
              </>
            )}
            {props.category === 'perfume' && (
              <>
                <option value="Mulher">Mulher</option>
                <option value="Homem">Homem</option>
                <option value="Crianças">Crianças</option>
              </>
            )}
          </select>
        </div>
        
        <div className="category-sort">
          <select value={sort} onChange={(e) => { setSort(e.target.value) }} name="sort" className='sort-selector'>
            <option value="" disabled hidden>Ordenar por</option>
            <option value="Preço Ascendente">Preço Ascendente</option>
            <option value="Preço Descendente">Preço Descendente</option>
          </select>
        </div>
      </div>

      <div className="shopcategory-product">
        {renderCategoryItems()}
      </div>

    </div>
  );
}

export default ShopCategory;
