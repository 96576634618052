import React from 'react';
import './Payment.css';
import { useEffect, useContext, useState } from 'react';
import { ShopContext } from '../../Context/ShopContext';
import mpesa from '../../Components/Assents/mpesa.png';
import emola from '../../Components/Assents/emola.jpeg';
import zara from '../../Components/Assents/zaramz.png';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import cloneDeep from 'lodash/cloneDeep';
const validator = require('validator');

const API_HOST = process.env.REACT_APP_API_HOST;

export const Payment = () => {
    const { getTotalCartAmount, cartItems, getTotalCartItems} = useContext(ShopContext);
    const [mpesaVisible, setMpesaVisible] = useState(false);
    const [emolaVisible, setEmolaVisible] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [callNumber, setCallNumber] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");

    useEffect(() => {
        const authToken = localStorage.getItem('auth-token');

        if (authToken) {
            try {
                const decoded = jwtDecode(authToken);
                setEmail(decoded.user.email || "");
                setName(decoded.user.name || "");
            } catch (error) {
                console.error('Erro ao decodificar o token:', error);
            }
        }
    }, []);
      
      
    const handleMpesaClick = () => {
        setMpesaVisible(true);
        setEmolaVisible(false);
      };
    
    const handleEmolaClick = () => {
        setMpesaVisible(false);
        setEmolaVisible(true);
    };

    const getCartDetails = () => {
        let details = '<table border="1" cellspacing="0" cellpadding="5" style="border-collapse: collapse; width: 100%;">';
        details += `
            <thead>
                <tr>
                    <th>Produto</th>
                    <th>Preço (MZN)</th>
                    <th>Quantidade Total</th>
                </tr>
            </thead>
            <tbody>
        `;
    
        for (const itemId in cartItems) {
            if (cartItems.hasOwnProperty(itemId)) {
                const cartItem = cartItems[itemId];
    
                if (cartItem) {
                    let totalQuantity = 0;
    
                    for (let i = 0; i < 3; i++) {
                        const quantityKey = `quantity${i}`;
                        if (cartItem.hasOwnProperty(quantityKey)) {
                            totalQuantity += cartItem[quantityKey] || 0;
                        }
                    }
    
                    if (totalQuantity > 0) {
                        details += `
                            <tr>
                                <td>${cartItem.name || 'Nome não disponível'}</td>
                                <td>${cartItem.price || 'Preço não disponível'} MZN</td>
                                <td>${totalQuantity}</td>
                            </tr>
                        `;
                    }
                }
            }
        }
    
        details += `
            </tbody>
        </table>
        `;
    
        return details;
    };
    
    const sendPaymentConfirmationEmail = () => {
        const cartDetails = getCartDetails();
        const totalAmount = getTotalCartAmount();
    
        const emailBody = `
            <p>Olá,</p>
            <p>Seu pagamento foi confirmado com sucesso! Abaixo estão os detalhes dos produtos:</p>
            ${cartDetails}
            <p><strong>Valor total:</strong> ${totalAmount} MZN</p>
            <p>Em breve você receberá mais detalhes sobre o seu pedido.</p>
            <p>Obrigado por comprar conosco!</p>
            <p>Atenciosamente,<br>ZaraMz</p>
        `;
    
        fetch(`${API_HOST}/sendConfirmationEmail`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                recipientEmail: `${email}`,
                subject: "Confirmação de Pagamento",
                html: emailBody
            }),
        })
        .then((response) => response.text())
        .then((data) => console.log(data))
        .catch((error) => console.error('Error sending email:', error));
    };

    
    const processOrder = (cart, transactionId) => {
        let cartOrders = [];
        for (const item in cart) {
            if (cart[item].quantity0 > 0 || cart[item].quantity1 > 0 || cart[item].quantity2 > 0) {
                cartOrders.push({
                    link: cart[item].link,
                    name: cart[item].name,
                    sizes: cart[item].sizes,
                    price: cart[item].price,
                    color: cart[item].color,
                    productId: cart[item].productId
                });
            }
        }
        
        const order = {
            items: cartOrders,
            phoneNumber: phoneNumber,
            callNumber: callNumber,
            email: email,
            name: name,
            status: "Recebido",
            price: getTotalCartAmount(),
            transactionId: transactionId 
        }

        fetch(`${API_HOST}/sendOrder`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(order),
            })
            .then((response) => response.text())
            .catch((error) => console.error('Error:', error));
    };

    const processPayment = () => {
        if((phoneNumber === "" && callNumber === "" && email === "" && name ==="") || (callNumber === "" && email === "")
         || (phoneNumber === "" && callNumber === "") || (phoneNumber === "" && email === "") || (email === "" && name ==="") 
        || ( callNumber === "" && name ==="") || ( phoneNumber === "" && name ==="") ) {
            setMessage("Por favor, preenche todos os dados");
        }else if (phoneNumber.match(/^8\d{8}$/) === null ) {
            setMessage("Número da conta inválida.");
        } else if (name === "") {
            setMessage("Nome inválido");
        }else if (callNumber.match(/^8\d{8}$/) === null) {
            setMessage("Número de chamadas inválido.");
        } else if (validator.isEmail(email) === false) {
            setMessage("Enderço de email inválido.");
        } else {
            
            if(message !== "Processando pagamento...") {
                sendPaymentRequest();
            }
     
        }
    };

    const sendPaymentRequest = async () => {
        try {
            setMessage('Processando pagamento...');
            
            const cartCopy = cloneDeep(cartItems);
            
            const response = await axios.post(`${API_HOST}/payment`, {
                phone: phoneNumber,
                cartItems: cartCopy
            }, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
    
            if(response.data.success) {
                setMessage(response.data.message);
                sendPaymentConfirmationEmail();
                processOrder(cartCopy, response.data.transactionId);
            } else {
                setMessage(response.data.message || 'Pagamento não realizado');
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const serverMessage = error.response.data.message;
                
                switch (serverMessage) {
                    case 'Erro ao realizar transação':
                        setMessage('Houve um problema ao processar seu pagamento. Por favor, tente novamente.');
                        break;
                    case 'Token inválido':
                        setMessage('Seu token de autenticação expirou. Por favor, faça login novamente.');
                        break;
                    default:
                        setMessage('Desculpe, algo deu errado. Tente novamente mais tarde.');
                }
            } else {
                setMessage('Não foi possível processar o pagamento no momento. Verifique sua conexão com a internet ou tente novamente mais tarde.');
            }
        }
    };
    
    
    return (
        <div className='payment'>
            <div className="zaraP">
                <img src={zara} alt='/' />
            </div>
            <div className="paymentContainer">
                <h1>Pagamento</h1>
                <h2>Valor a pagar: {getTotalCartAmount()} MZN</h2>
                <h2>Seleccione o método de pagamento</h2>

                <div className="paymentMeth">
                    <div className="mpesa button" onClick={handleMpesaClick}>
                        <img src={mpesa} alt='/' />
                    </div>

                    <div className="emola button" onClick={handleEmolaClick}>
                        <img src={emola} alt='/' />
                    </div>
                </div>

                {(mpesaVisible || emolaVisible) && (
                    <div className="contactDetails">
                        <p>Introduza o seu número da conta</p>
                        <input type="tel" value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)} placeholder="Número da conta" />

                        <p>Introduza o seu número de chamadas</p>
                        <input type="tel" value={callNumber} onChange={(event) => setCallNumber(event.target.value)} placeholder="Número de chamadas" />
                        
                        {(!localStorage.getItem('auth-token')) && (
                            <>
                                <p>Introduza o seu nome</p>
                                <input type="text" value={name} onChange={(event) => setName(event.target.value)} placeholder="Nome do cliente" />

                                <p>Introduza o seu email</p>
                                <input type="email" value={email} onChange={(event) => setEmail(event.target.value)} placeholder="Enderço de email" />
                            </>
                        )}


                        <div className="bttnPay">
                            <button onClick={processPayment}>
                                Pagar
                            </button>
                        </div>
                    </div>
                )}

                <div className="msg">
                    <p>{message}</p>
                </div>
            </div>
        </div>
    );
};
export default Payment;