// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-collection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 100px;
}
.new-collection h1 {
    color: #171717;
    font-size: 50px;
    font-weight: 600;
}
.new-collection hr {
    width: 200px;
    height: 6px;
    border-radius: 10px;
    background: #252525;
}
.new-collection-item {
    margin-top: 50px;
    display: flex;
    gap: 30px;
}
.collections {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 50px;
    gap: 30px;
}
@media(max-width:1900px) {
    .new-collection h1 {
        font-size: 40px;
    }
}
@media(max-width:1024px) {
    .new-collection h1 {
        font-size: 30px;
    }
    .new-collection hr {
        width: 120px;
        height: 3px;
    }
    .collections {
        gap: 15px;
        margin-top: 20px;
    }
}
@media(max-width:800px) {
    .new-collection h1 {
        font-size: 20px;
    }
    .new-collection hr {
        width: 100px;
    }
    .collections {
        gap: 5px;
    }
}
@media(max-width:540px) {
    .collections {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/NewCollection/NewCollection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,oBAAoB;AACxB;AACA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,SAAS;AACb;AACA;IACI,aAAa;IACb,sCAAsC;IACtC,gBAAgB;IAChB,SAAS;AACb;AACA;IACI;QACI,eAAe;IACnB;AACJ;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,YAAY;QACZ,WAAW;IACf;IACA;QACI,SAAS;QACT,gBAAgB;IACpB;AACJ;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,YAAY;IAChB;IACA;QACI,QAAQ;IACZ;AACJ;AACA;IACI;QACI,aAAa;QACb,8BAA8B;QAC9B,SAAS;IACb;AACJ","sourcesContent":[".new-collection {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n    margin-bottom: 100px;\n}\n.new-collection h1 {\n    color: #171717;\n    font-size: 50px;\n    font-weight: 600;\n}\n.new-collection hr {\n    width: 200px;\n    height: 6px;\n    border-radius: 10px;\n    background: #252525;\n}\n.new-collection-item {\n    margin-top: 50px;\n    display: flex;\n    gap: 30px;\n}\n.collections {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    margin-top: 50px;\n    gap: 30px;\n}\n@media(max-width:1900px) {\n    .new-collection h1 {\n        font-size: 40px;\n    }\n}\n@media(max-width:1024px) {\n    .new-collection h1 {\n        font-size: 30px;\n    }\n    .new-collection hr {\n        width: 120px;\n        height: 3px;\n    }\n    .collections {\n        gap: 15px;\n        margin-top: 20px;\n    }\n}\n@media(max-width:800px) {\n    .new-collection h1 {\n        font-size: 20px;\n    }\n    .new-collection hr {\n        width: 100px;\n    }\n    .collections {\n        gap: 5px;\n    }\n}\n@media(max-width:540px) {\n    .collections {\n        display: grid;\n        grid-template-columns: 1fr 1fr;\n        gap: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
