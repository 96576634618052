import React from 'react';
import exclusive_image from '../Assents/exclusive_img.png';
import './Offers.css';
import { Link } from 'react-router-dom';

export const Offers = () => {
  return (
    <div className='offers'>
        <div className="offers-left">
            <h1>Exclusivo</h1>
            <h1>Ofertas Para Você</h1>
            <p>Somente Nos Produtos Mais Vendidos</p>
            <Link to="/womens">
              <button>Veja Agora</button>
            </Link>
        </div>

        <div className="offers-right">
            <img src = {exclusive_image} alt=''/>
        </div>
    </div>
  )
}

export default Offers;