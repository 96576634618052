import React, {useContext} from 'react';
import './Hero.css';
import hand_icon from '../Assents/hand_icon.png';
import arrow_icon from '../Assents/arrow.png';
import hero_img from '../Assents/hero_image.png';
import { ShopContext } from '../../Context/ShopContext';

export const Hero = () => {

    const { scrollToNewCollection, setScrollToNewCollection } = useContext(ShopContext);

    const scrollToNewCollectionFunction = () => {
        setScrollToNewCollection(prevState => !prevState);
    };
    return (
        <div className='hero'>
            <div className="hero-left">
                <h2>SOMENTE NOVIDADES</h2>
                <div>
                    <div className="hero-hand-icon">
                        <p>novas</p>
                        <img src={ hand_icon } alt="" />
                    </div>
                    <p>coleções</p>
                    <p>para todos</p>
                </div>
                <div className="hero-latest-btn">
                    <div onClick={scrollToNewCollectionFunction} >Últimas coleções</div>
                    <img src={arrow_icon} alt="" />
                </div>
            </div>

            <div className="hero-right">
                <img src={hero_img} alt="" />

            </div>
        </div>
    )
}

export default Hero;