import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <div className='footer'>
            <div className="footer-logo">
                <p className='zara'>ZARA - MZ</p>
            </div>
            <div className='footer-links'>
                <li><Link style={{ color: 'black', textDecoration: "none"}} to="/company"> Empresa </Link></li>
                <li><Link style={{ color: 'black', textDecoration: "none"}} to="/products"> Produtos </Link></li>
                <li><Link style={{ color: 'black', textDecoration: "none"}} to="/offices"> Escritórios </Link></li>
                <li><Link style={{ color: 'black', textDecoration: "none"}} to="/about"> Sobre </Link></li>
                <li><Link style={{ color: 'black', textDecoration: "none"}} to="/contact"> Contato </Link></li>
            </div>
            <div className="footer-copyright">
                <hr />
                <p>Copyright @ 2024 - Todos Os Direitos Reservados</p>
            </div>
        </div>
    )
}

export default Footer;