import React from 'react';
import './Item.css';
import { Link } from "react-router-dom";

export const Item = (props) => {
    return (
        <div className='item'>
            <Link to={`/product/${props.id}`} onClick={() => window.scrollTo(0, 0)}>
                <img src={props.image} alt={props.name} className="item-image" />
            </Link>
            <p className="item-name">{props.name}</p>

            <div className="item-prices">
                <div className="item-price-new">
                    MZN {props.new_price}
                </div>
                {props.old_price && props.old_price !== '' && (
                    <div className="item-price-old">
                        MZN {props.old_price}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Item;
