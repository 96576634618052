// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descriptionBox {
    margin: 120px 170px;
}
.descriptionBox-navigator {
    display: flex;
}
.descriptionBox-nav-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width: 171px;
    height: 70px;
    border: 1px solid #d0d0d0;
}
.descriptionBox-nav-box.fade {
    background: #FBFBFB;
    color: #555;
}
.decriptionBox-description {
    display: flex;
    flex-direction: column;
    gap: 25px;
    border: 1px solid #D0D0D0;
    padding: 48px;
    padding-bottom: 70px;
}
@media(max-width:1280px) {
    .descriptionBox {
        margin: 60px;
    }
}
@media(max-width:1280px) {
    .descriptionBox {
        margin: 60px;
    }
}
@media(max-width:800px) {
    .descriptionBox {
        margin: 60px 20px;
    }
    .decriptionBox-description {
        font-size: 14px;
    }
    .descriptionBox-nav-box {
        font-size: 14px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/DescriptionBox/DescriptionBox.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,yBAAyB;AAC7B;AACA;IACI,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,yBAAyB;IACzB,aAAa;IACb,oBAAoB;AACxB;AACA;IACI;QACI,YAAY;IAChB;AACJ;AACA;IACI;QACI,YAAY;IAChB;AACJ;AACA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".descriptionBox {\n    margin: 120px 170px;\n}\n.descriptionBox-navigator {\n    display: flex;\n}\n.descriptionBox-nav-box {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 16px;\n    font-weight: 600;\n    width: 171px;\n    height: 70px;\n    border: 1px solid #d0d0d0;\n}\n.descriptionBox-nav-box.fade {\n    background: #FBFBFB;\n    color: #555;\n}\n.decriptionBox-description {\n    display: flex;\n    flex-direction: column;\n    gap: 25px;\n    border: 1px solid #D0D0D0;\n    padding: 48px;\n    padding-bottom: 70px;\n}\n@media(max-width:1280px) {\n    .descriptionBox {\n        margin: 60px;\n    }\n}\n@media(max-width:1280px) {\n    .descriptionBox {\n        margin: 60px;\n    }\n}\n@media(max-width:800px) {\n    .descriptionBox {\n        margin: 60px 20px;\n    }\n    .decriptionBox-description {\n        font-size: 14px;\n    }\n    .descriptionBox-nav-box {\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
