import React from 'react';
import './Details.css';

export const Details = (props) => {
    let title = '';
    if (props.type === "company") {
        title = 'Empresa';
    } else if (props.type === "products") {
        title = 'Produtos';
    } else if (props.type === "offices") {
        title = 'Escritórios';
    } else if (props.type === "about") {
        title = 'Sobre Nós';
    } else if (props.type === "contact") {
        title = 'Contactos';
    }

  return (
    <div className='details'>
      <div className="title">
        <h1>{title}</h1>
      </div>
    </div>
  );
};

export default Details;
