import React from 'react';
import arrow_icon from '../Assents/breadcrum_arrow.png';
import './Breadcrumbs.css';

export const Breadcrumbs = (props) => {

    const {product} = props;

    return (
        <div className='breadcum'>
            HOME <img src={arrow_icon} alt='' />
            SHOP <img src={arrow_icon} alt='' />
            {product.category} <img src={arrow_icon} alt='' />
            {product.name}
        </div>
    )
}

export default Breadcrumbs;