import React, { useState, useContext, useEffect } from 'react';
import './ProductDisplay.css';
import { ShopContext } from '../../Context/ShopContext';

export const ProductDisplay = (props) => {
    const { product } = props;
    const { addToCart } = useContext(ShopContext);

    const [productImg, setProductImg] = useState(product.images);
    const [selectedSize, setSize] = useState(null);
    const [information, setInformation] = useState("");
    const [i, setI] = useState(0);

    const handleMainImageClick = (index) => {
        const cpyImg = [...productImg];
        const aux = cpyImg[index];
        cpyImg[index] = cpyImg[0+i];
        cpyImg[0+i] = aux;
        setProductImg(cpyImg);
    };
    const processRequest = () => {
        if(selectedSize === null && product.category !== "perfume") {
            if(product.type === 'Bolsas')
                setInformation(" Mensagem: Selecione a cor");
            else
                setInformation(" Mensagem: Selecione o tamanho");
        } else {
            addToCart(product, selectedSize, i);
        }
    };
    const selectSizeHandler = (size) => {
        if(product.type === 'Bolsas') {
            const index = product.sizes.indexOf(size);
            if (index !== -1) {
              setI(index * 5);
            }
        }

        setSize(size);
        setInformation("");
    };

    useEffect(() => {
        if (product.type === 'Bolsas' && product.sizes.length > 0) {
            selectSizeHandler(product.sizes[0]);
        } 
    }, [product.type, product.sizes]);

    return (
        <div className='productDisplay'>
            <div className="productDisplay-left">
                <div className="productDispaly-img-list">
                    <img onClick={() => handleMainImageClick(1+i)} src={productImg[1+i]} alt='' />
                    <img onClick={() => handleMainImageClick(2+i)} src={productImg[2+i]} alt='' />
                    <img onClick={() => handleMainImageClick(3+i)} src={productImg[3+i]} alt='' />
                    <img onClick={() => handleMainImageClick(4+i)} src={productImg[4+i]} alt='' />
                </div>

                <div className="productDisplay-img">
                    <img className="productDisplay-main-img" src={productImg[0+i]} alt='' />
                </div>
            </div>

            <div className="productDisplay-right">
                <h1>{product.name}</h1>

                <div className="productDisplay-right-prices">
                    {product.old_price && product.old_price !== '' && (
                        <div className="productDisplay-price-old">
                            MZN {product.old_price}
                        </div>
                    )}
                    <div className="productDisplay-price-new">
                        MZN {product.new_price}
                    </div>
                </div>
                    
                <div className="productDisplay-right-description">
                    {product.description}
                </div>
                
                {product.color && product.color !== '' && (
                     <div className="productDisplay-color">
                       <p><strong>Cor:</strong> {product.color}</p> 
                     </div>    
                )}

                {product.category !== "perfume" && (
                    <div className={`productDisplay-right-size ${product.category === 'kid' ? 'category-kid' : ''}`}>
                        {product.type === 'Bolsas' ? (<h1>Selecione a Cor</h1>): (<h1>Selecione o Tamanho</h1>)} 

                        <div className="productDisplay-right-sizes">
                            {product.sizes.map((size, index) => (
                                <div key={index} style={{ 
                                    backgroundColor: selectedSize === size ? '#ADD8E6' : 'white',
                                    cursor: 'pointer'
                                }} onClick={() => {selectSizeHandler(size)}}>{size}</div>
                            ))}
                        </div>
                    </div>
                )}

                <button onClick={() => {processRequest()}}>ADICIONAR AO CARRINHO</button>
                <p className='productDisplay-right-category productDisplay-right-info'>{information} </p>
            </div>
        </div>
    )
}

export default ProductDisplay;
