import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import './LoginSignup.css';

const API_HOST = process.env.REACT_APP_API_HOST;

export const LoginSignup = () => {
    const [state, setState] = useState("Conecte-se");
    const [info, setInfo] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [step, setStep] = useState(1);
    const [resetPassword, setResetPassword] = useState(false);

    const [formData, setFormData] = useState({
        username: "",
        password: "",
        passwordConf: "",
        email: "",
        newPassword: "",
        confirmNewPassword: ""
    });

    const changeHandler = (e) => {
        setInfo("");
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const login = async () => {
        try {
            const response = await fetch(`${API_HOST}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email: formData.email, password: formData.password}),
            });
    
            const responseData = await response.json();
    
            if (responseData.success) {
                const token = responseData.token;
                localStorage.setItem('auth-token', token);
    
                window.location.replace("/");
            } else {
                setInfo(responseData.error);
            }
        } catch (error) {
            setInfo("Ocorreu um erro, tente novamente.");
        }
    };
    
    const validatePassword = (password) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        const isLengthValid = password.length >= 8;

        return hasUpperCase && hasNumber && isLengthValid;
    };

    const signup = async () => {
        if (formData.password !== formData.passwordConf) {
            setInfo("As senhas não coincidem");
            return;
        }

        if (!validatePassword(formData.password)) {
            setInfo("A senha deve ter pelo menos um número, uma letra maiúscula e 8 caracteres no mínimo");
            return;
        }

        try {
            const response = await fetch(`${API_HOST}/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const responseData = await response.json();

            if (responseData.success) {
                setInfo(responseData.message);
                setStep(2);
            } else {
                setInfo(responseData.error);
            }
        } catch (error) {
            setInfo("Ocorreu um erro. Tente novamente.");
        }
    };

    const verifyCode = async () => {
        let responseData;
        if(state === "Redefinir Palavra passe") {
            try {
                const response = await fetch(`${API_HOST}/reset-password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: formData.email, verificationCode: verificationCode, newPassword: formData.newPassword }),
                });
    
                const responseData = await response.json();
    
                if (responseData.success) {
                    setInfo(responseData.message);
                    setState("Conecte-se");
                    setResetPassword(false);
                    setStep(1);
                } else {
                    setInfo(responseData.error);
                }
            } catch (error) {
                setInfo("Ocorreu um erro ao redefinir a senha. Tente novamente.");
            }
        } else {
            await fetch(`${API_HOST}/verify-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: formData.email, verificationCode }),
            }).then((response) => response.json()).then((data) => responseData = data);
    
            if (responseData.success) {
                const token = responseData.token;
                localStorage.setItem('auth-token', token);

                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000 - Date.now();

                setTimeout(() => {
                    alert('Sua sessão expirou. Por favor, faça login novamente.');
                    localStorage.removeItem('auth-token');
                    window.location.replace("/login");
                }, expirationTime);

                window.location.replace("/");
            } else {
                setInfo(responseData.error);
            }
        }
    };

    const resetPasswordRequest = async () => {
        if (formData.newPassword !== formData.confirmNewPassword) {
            setInfo("As senhas não coincidem");
            return;
        }

        if (!validatePassword(formData.newPassword)) {
            setInfo("A nova senha deve ter pelo menos um número, uma letra maiúscula e 8 caracteres no mínimo");
            return;
        }

        try {
            const response = await fetch(`${API_HOST}/request-reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: formData.email }),
            });

            const responseData = await response.json();

            if (responseData.success) {
                setInfo(responseData.message);
                setResetPassword(false);
                setStep(2);
                setState("Redifinir Palavra passe");
            } else {
                setInfo(responseData.error);
            }
        } catch (error) {
            setInfo("Ocorreu um erro ao solicitar a redefinição de senha. Tente novamente.");
        }
    };

    return (
        <div className='loginsigup'>
            <div className="loginsignup-container">
                <h1>{resetPassword ? "Redefinir Palavra Passe" : state}</h1>

                {!resetPassword ? (
                    <div className="loginsignup-fields">
                        {step === 1 ? (
                            <>
                                {state === "Inscrever-se" && <input name='username' value={formData.username} onChange={changeHandler} type="text" placeholder='Digite Seu Nome' />}
                                <input name='email' value={formData.email} onChange={changeHandler} type='email' placeholder='Digite o seu endereço de email' />
                                <input name='password' value={formData.password} onChange={changeHandler} type='password' placeholder='Digite sua palavra passe' />
                                {state === "Inscrever-se" && <input name='passwordConf' value={formData.passwordConf} onChange={changeHandler} type='password' placeholder='Digite sua palavra passe novamente' />}
                            </>
                        ) : (
                            <>
                                <input name='verificationCode' value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} type='text' placeholder='Digite o código de verificação' />
                            </>
                        )}
                    </div>
                ) : (
                    <div className="loginsignup-fields">
                        <input name='email' value={formData.email} onChange={changeHandler} type='email' placeholder='Digite o seu endereço de email' />
                        <input name='newPassword' value={formData.newPassword} onChange={changeHandler} type='password' placeholder='Digite sua nova palavra passe' />
                        <input name='confirmNewPassword' value={formData.confirmNewPassword} onChange={changeHandler} type='password' placeholder='Confirme sua nova palavra passe' />
                    </div>
                )}

                {!resetPassword ? (
                    step === 1 ? (
                        <button onClick={() => { state === "Conecte-se" ? login() : signup() }}>
                            {state === "Conecte-se" ? "Entrar" : "Cadastrar-se"}
                        </button>
                    ) : (
                        <button onClick={verifyCode}>
                            Verificar Código
                        </button>
                    )
                ) : (
                    <button onClick={resetPasswordRequest}>
                        Redefinir Senha
                    </button>
                )}

                {!resetPassword && state === "Conecte-se" && (
                    <>
                        <p className='loginsignup-login'>
                            Quer criar uma conta? 
                            <span onClick={() => { setInfo(""); setState("Inscrever-se") }}> Clique aqui </span>
                        </p>
                        <p className='loginsignup-login'>
                            Esqueceu a palavra passe? 
                            <span  onClick={() => { setInfo(""); setResetPassword(true); }}> Clique aqui </span>
                        </p>
                    </>
                )}

                {resetPassword && (
                    <p className='loginsignup-login'>
                        Lembrou sua senha? 
                        <span onClick={() => { setResetPassword(false); setState("Conecte-se"); setInfo(""); }}> Clique aqui </span>
                    </p>
                )}

                {state === "Inscrever-se" && (
                <>
                    {step !== 2 && (
                    <p className='loginsignup-login'>
                        Ao continuar, concorda com os termos de uso e política de privacidade.
                    </p>
                    )}
                    <p className='loginsignup-login'>
                    Já tem uma conta?  
                    <span onClick={() => { 
                        setInfo(""); 
                        setState("Conecte-se"); 
                        setResetPassword(false); 
                        setStep(1);
                    }}>
                        Clique aqui
                    </span>
                    </p>
                </>
                )}


                <div className='loginsignup-login loginsignup-info'>
                    <p dangerouslySetInnerHTML={{ __html: info }}></p>
                </div>
            </div>
        </div>
    );
};

export default LoginSignup;
