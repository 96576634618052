import React, { useContext, useState } from 'react';
import './CartItems.css';
import { ShopContext } from '../../Context/ShopContext';
import remove_icon from '../Assents/cart_cross_icon.png';
import { Link } from 'react-router-dom';

export const CartItems = () => {

    const { all_product, cartItems, removeFromCart, getTotalCartAmount } = useContext(ShopContext);
    const [info, setInfo] = useState("");
    const [code, setCode] = useState("");

    const sendPromoCode = () => {
        setInfo("Código inválido");
        setCode("");
    };
    const renderCartItem = (cartItem, quantityIndex, product) => {
        const quantity = cartItem[`quantity${quantityIndex}`];
        
        if (quantity > 0) {
            return (
                <div key={quantityIndex}>
                    <div className="caratItems-format cartitems-format-main">
                        <img src={product.images[quantityIndex > 0 ? ((quantityIndex)* 5) : 0]} alt='' className='cartIcon-productIcon' />
                        <p>{cartItem.name}</p>
                        <p>MZN {cartItem.price}</p>
                        <button className='cartItems-quantity'>
                            {quantity}
                        </button>
                        <p> MZN {cartItem.price * quantity}</p>
                        <img className='cartIcon-remove-icon' src={remove_icon} onClick={() => removeFromCart(product, quantityIndex)} alt='' />
                    </div>
                    <hr />
                </div>
            );
        }
        return null;
    };
    const renderCartItems = (all_product) => {
        return all_product.map((product) => {
            const cartItem = cartItems[product.id];
            if (cartItem) {
                const cartItemsJSX = [];
    
                // Iterate over possible quantity indices
                for (let i = 0; i <= 2; i++) {
                    const item = renderCartItem(cartItem, i, product);
                    if (item) {
                        cartItemsJSX.push(item);
                    }
                }
    
                return cartItemsJSX.length > 0 ? cartItemsJSX : null;
            }
            return null;
        });
    };
    return (
        <div className='cartItems'>
            <div className="cartitems-format-main">
                <p>Produto</p>
                <p>Título</p>
                <p>Preço</p>
                <p>Quantidade</p>
                <p>Total</p>
                <p>Remover</p>
            </div>
            <hr/>
            <div>
            <div>
                {renderCartItems(all_product)}
            </div>

                <div className="cartItems-down">
                    <div className="cartItems-total">
                        <h1>Total Do Carrinho</h1>
                        <div>
                            <div className="cartItems-total-item">
                                <p>Subtotal</p>
                                <p>MZN {getTotalCartAmount()}</p>
                            </div>
                            <hr />
                            <div className="cartItems-total-item">
                                <p>Valor Do Transporte</p>
                                <p>Gratis</p>
                            </div>
                            <hr />
                            <div className="cartItems-total-item">
                                <h3>Total</h3>
                                <h3>MZN {getTotalCartAmount()}</h3>
                            </div>
                        </div>
                        {getTotalCartAmount() > 0 && (
                            <Link to="/payment">
                                <button>FAZER O PAGAMENTO</button>
                            </Link>
                        )}
                
                    </div>
                    <div className="cartItems-promocode">
                        <p> Se você tiver um código promocional, insira-o aqui</p>
                        <div className="cartItems-promoBox">
                            <input value={code} onChange={(event) => setCode(event.target.value)} type='text' placeholder='código promocional' />
                            <button onClick={sendPromoCode}>Enviar</button>
                            <p>{info}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartItems;