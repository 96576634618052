import React, { useContext, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./Navbar.css";
import cart_icon from '../Assents/cart_icon.png';
import { ShopContext } from '../../Context/ShopContext';
import dropdown_icon from '../Assents/dropdown-icon.png';
import { jwtDecode } from 'jwt-decode'; 

export const Navbar = () => {
    const [menu, setMenu] = useState("shop");
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const { getTotalCartItems } = useContext(ShopContext);
    const menuRef = useRef();

    const dropdown_toggle = (e) => {
        menuRef.current.classList.toggle('nav-menu-visible');
        e.target.classList.toggle('open');
    }
    useEffect(() => {
        menuRef.current.classList.toggle('nav-menu-visible');
    }, []);

    useEffect(() => {
        const checkTokenExpiration = () => {
            const token = localStorage.getItem('auth-token');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    const expirationTime = decodedToken.exp * 1000;
                    if (Date.now() >= expirationTime) {
                        localStorage.removeItem('auth-token');
                        setIsTokenExpired(true);
                    }
                } catch (error) {
                    console.error('Error decoding token:', error);
                    setIsTokenExpired(true);
                }
            }
        };

        checkTokenExpiration();
    }, []);

    return (
        <div className="navbar">
            <div className="nav-logo" onClick={() => { setMenu("shop") }}>
                <Link to="/" style={{ textDecoration: "none" }}>
                    <p className='zara'>ZARA - MZ</p>
                </Link>
            </div>
            <img className='nav_dropdown' onClick={dropdown_toggle} src={dropdown_icon} alt='Dropdown icon' />
            <ul ref={menuRef} className="nav-menu">
                <li onClick={() => { setMenu("shop") }}>
                    <Link style={{ textDecoration: "none" }} to="/"> Shop </Link>
                    {menu === "shop" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("mens") }}>
                    <Link style={{ textDecoration: "none" }} to="/mens"> Homem </Link>
                    {menu === "mens" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("womens") }}>
                    <Link style={{ textDecoration: "none" }} to="/womens"> Mulher </Link>
                    {menu === "womens" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("kids") }}>
                    <Link style={{ textDecoration: "none" }} to="/kids"> Crianças </Link>
                    {menu === "kids" ? <hr /> : <></>}
                </li>
                <li onClick={() => { setMenu("perfumes") }}>
                    <Link style={{ textDecoration: "none" }} to="/perfumes"> Perfumes </Link>
                    {menu === "perfumes" ? <hr /> : <></>}
                </li>
            </ul>
            <div className="nav-login-cart">
                {isTokenExpired || !localStorage.getItem('auth-token') ? (
                    <Link to="/login">
                        <button>
                            Login
                        </button>
                    </Link>
                ) : (
                    <button onClick={() => {
                        localStorage.removeItem('auth-token');
                        window.location.replace('/');
                    }}>
                        Logout
                    </button>
                )}
                <Link to="/cart">
                    <img src={cart_icon} alt='Cart icon' />
                </Link>
                <div className="nav-cart-count">
                    {getTotalCartItems()}
                </div>
            </div>
        </div>
    );
};

export default Navbar;
