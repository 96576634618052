import React from 'react';
import './DescriptionBox.css';

export const DescriptionBox = () => {
  return (
    <div className='descriptionBox'>
        <div className="descriptionBox-navigator">
            <div className="descriptionBox-nav-box">
                Descrição
            </div>
            <div className="descriptionBox-nav-box fade">
                Avaliações
            </div>
        </div>
        <div className="decriptionBox-description">
            <p>Um site de comércio eletrônico é uma plataforma online que facilita a compra e venda de produtos ou serviços pela internet.
                Serve como um mercado virtual onde empresas e indivíduos podem mostrar seus produtos, interagir com clientes,
                e realizar transações sem a necessidade de presença física. Os sites de comércio eletrônico ganharam imensa popularidade devido à sua conveniência,
                acessibilidade e o alcance global que oferecem.
            </p>
            <p> Os sites de comércio eletrônico normalmente exibem produtos ou serviços junto com descrições detalhadas, imagens, preços e quaisquer variações disponíveis.
            (por exemplo, tamanhos, cores). Cada produto geralmente tem sua própria página dedicada com informações relevantes.</p>
        </div>
    </div>
  )
}

export default DescriptionBox;