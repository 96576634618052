import './App.css';
import { Navbar } from './Components/Navbar/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Shop from './Pages/Shop/Shop';
import ShopCategory from './Pages/ShopCategory/ShopCategory';
import Product from './Pages/Product/Product';
import Cart from './Pages/Cart/Cart';
import LoginSignup from './Pages/LoginSignup/LoginSignup';
import Footer from './Components/Footer/Footer';
import men_banner from './Components/Assents/banner_mens.png';
import women_banner from './Components/Assents/banner_women.png';
import kid_banner from './Components/Assents/banner_kids.png';
import perfume_banner from './Components/Assents/banner_perfumes.jpg';
import Details from './Pages/Details/Details';
import Payment from './Pages/Payment/Payment';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Shop />} />
            <Route path="/mens" element={<ShopCategory banner={men_banner} category="men" />} />
            <Route path="/womens" element={<ShopCategory banner={women_banner} category="women" />} />
            <Route path="/kids" element={<ShopCategory banner={kid_banner} category="kid" />} />
            <Route path="/perfumes" element={<ShopCategory banner={perfume_banner} category="perfume" />} />
            <Route path="/company" element={<Details type="company" />} />
            <Route path="/products" element={<Details type="products" />} />
            <Route path="/offices" element={<Details type="offices" />} />
            <Route path="/about" element={<Details type="about" />} />
            <Route path="/contact" element={<Details type="contact" />} />
            <Route path="/product" element={<Product />}>
              <Route path=':productId' element={<Product />} />
            </Route>
            <Route path="/payment" element={<Payment />} />
            <Route path="cart" element={<Cart />} />
            <Route path='/login' element={<LoginSignup />} />
          </Routes>
          <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
