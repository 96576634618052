// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(max-height: 500px) {
    .product {
        margin-bottom: 100px;
    }
}
@media(max-height: 350px) {
    .product {
        margin-bottom: 300px;
    }
}
@media(max-width: 500px) {
    .product {
        margin-bottom: 200px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Product/Product.css"],"names":[],"mappings":"AAAA;IACI;QACI,oBAAoB;IACxB;AACJ;AACA;IACI;QACI,oBAAoB;IACxB;AACJ;AACA;IACI;QACI,oBAAoB;IACxB;AACJ","sourcesContent":["@media(max-height: 500px) {\n    .product {\n        margin-bottom: 100px;\n    }\n}\n@media(max-height: 350px) {\n    .product {\n        margin-bottom: 300px;\n    }\n}\n@media(max-width: 500px) {\n    .product {\n        margin-bottom: 200px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
