import React, { useEffect } from 'react';
import Hero from '../../Components/Hero/Hero';
import Popular from '../../Components/Popular/Popular';
import Offers from '../../Components/Offers/Offers';
import NewCollection from '../../Components/NewCollection/NewCollection';
import NewsLetter from '../../Components/NewsLetter/NewsLetter';
import './Shop.css';
import { useLocation } from 'react-router-dom';

const Shop = () => {
  const location = useLocation();


  useEffect(() => {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    if (scrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(scrollPosition, 10));
      }, 400); 
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      sessionStorage.setItem('scrollPosition', window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='shop'>
      <Hero />
      <Popular />
      <Offers />
      <NewCollection />
      <NewsLetter />
    </div>
  );
}

export default Shop;
